import { useExperience, useNinetailed } from '@ninetailed/experience.js-next';
import { useNinetailedAbTestContext } from 'contexts/ninetailed-ab-test';
import { IDiscountFields, IGlobalData } from 'global';
import React, { useEffect, useMemo } from 'react';
import { isVariantRef, MyVariant, parseExperiences } from 'utils/ninetailed';

type Discount = MyVariant<IDiscountFields>;

export interface GlobalDiscount {
  loading: boolean;
  discount: null | Discount;
}

/**
 * Retrieve global discount configuration based on personalization.
 * @TODO : globalDiscount is updated too many time. Should track down the reason why
 */
const useGlobalDiscount = (globalData: IGlobalData): GlobalDiscount => {
  const { setToHeader } = useNinetailedAbTestContext();
  const { track, trackComponentView } = useNinetailed();
  const _baseline = React.useMemo(
    () => ({
      ...globalData.fields.globalDiscount.fields,
      id: globalData?.fields.globalDiscount.sys.id || '',
    }),
    [globalData]
  );

  const experiences = parseExperiences({
    entry: globalData.fields.globalDiscount,
  });

  const { variant, audience, error, experience, loading, variantIndex } =
    useExperience<Discount, any>({
      baseline: _baseline,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      experiences,
    });

  const globalDiscount: GlobalDiscount | undefined = React.useMemo(() => {
    if (loading) {
      return {
        loading: true,
        discount: null,
      };
    }

    if (error) {
      return {
        loading: false,
        discount: null,
      };
    }

    if (variant && !isVariantRef(variant)) {
      return {
        loading: false,
        discount: {
          // When no variant is not found, variant is null.
          // here we have to assign default
          ..._baseline,
          ...variant,
          id: variant.id,
        },
      };
    } else {
      return {
        loading: false,
        discount: {
          ..._baseline,
        },
      };
    }
  }, [_baseline, error, loading, variant]);

  const seenBaselineFired = React.useRef(false);

  /**
   * If the baseline was seen we need to send an event, in order to persist this discount after login for the sake of consistency.
   * This should be removed once our tracking data is propagated through our customer base.
   */
  React.useEffect(() => {
    if (
      !seenBaselineFired.current &&
      loading === false &&
      audience &&
      audience.id === 'baseline' &&
      variant
    ) {
      track('hasSeenBaselineDiscount', { discountId: variant.id });
      seenBaselineFired.current = true;
    }
  }, [audience, loading, variant, track]);

  /**
   * Track global discount
   * @TODO : Add more detailed explanation.
   */
  React.useEffect(() => {
    if (isVariantRef(variant)) {
      trackComponentView({
        element: null as any,
        experience,
        audience,
        variant,
        variantIndex,
      });
    }
  }, [
    audience,
    experience,
    globalData,
    loading,
    trackComponentView,
    variant,
    variantIndex,
  ]);

  const experienceId = useMemo(() => {
    return experience?.id;
  }, [experience]);

  /**
   * Set to header
   */
  useEffect(() => {
    if (variantIndex !== undefined && experienceId) {
      setToHeader({
        experienceId,
        variant: variantIndex,
      });
    }
  }, [experienceId, setToHeader, variantIndex]);

  return globalDiscount;
};

export default useGlobalDiscount;
